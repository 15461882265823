export default [
    {
        key: "lead",
        label: "Lead",
        visible: true,
    },
    {
        key: "source",
        label: "Source",
        visible: true,
    },
    {
        key: "email",
        label: "Email",
        visible: true,
    },
    {
        key: "action",
        label: "Action",
        thClass: "text-center",
        tdClass: "text-center",
        visible: true,
    },
]
