<template>
  <b-table
    small
    no-provider-filtering
    ref="refCardsGrid"
    :items="myProvider"
    :fields="fields"
    primary-key="id"
    table-class="text-nowrap"
    responsive="sm"
    show-empty
    sticky-header="70vh"
    :busy.sync="isBusy"
    :sort-by.sync="sortBy"
    :sort-desc.sync="sortDesc"
    :current-page="paginate.currentPage"
    :per-page="paginate.perPage"
  >
    <template #table-busy>
      <div class="text-center text-primary my-2">
        <b-spinner class="align-middle mr-1"></b-spinner>
        <strong>Loading ...</strong>
      </div>
    </template>
  </b-table>
</template> 

<script>
export default {
  name: "CardsTable",
  props: {
    myProvider: {
      type: Function,
      required: true,
    },
    fields: {
      type: Array,
      required: true,
    },
    currentPage: {
      type: Number,
      required: true,
    },
    sortBy: {
      type: String,
      required: true,
    },
    sortDesc: {
      type: Boolean,
      required: true,
    },
    paginate: {
      type: Object,
      required: true,
    },
    moduleId: {
      type: Number,
      required: true,
    },
    roleId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      isBusy: false,
      assetsImg: process.env.VUE_APP_BASE_URL_ASSETS,
    };
  },
  components: {},
  methods: {},
  created() {
  },
};
</script>
<style scoped>
#icon {
  border-radius: 50%;
  margin-bottom: 2px;
  margin-right: 5px;
}

.center-number-in-icon {
  position: relative;
  bottom: 15px;
  left: 6.7px;
  font-size: 85%;
  font-weight: bold;
}
</style>
