<template>
  <div>
    <header-slot
      no-border-bottom
      v-if="moduleId !== 5"
      :clients-search="true"
      :global-search="true"
    >
      <template #actions>
        <div class="w-100 d-flex justify-content-end" style="gap: 15px">
          <b-input-group v-if="moduleId !== 2">
            <b-input-group-prepend>
              <b-button variant="outline-info">GLOBAL SEARCH</b-button>
            </b-input-group-prepend>
            <b-form-input
              v-model="searchText"
              @keyup.enter="search"
              placeholder="Search by Name, Account or the last 4 digits of Phone number"
              style="height: auto"
            />
            <b-input-group-append>
              <b-button variant="primary" @click="search">
                <feather-icon icon="SearchIcon" />
              </b-button>
            </b-input-group-append>
          </b-input-group>
          <b-button
            v-if="isFinancialApproval"
            variant="success"
            @click="showModalCard('amg-cards')"
            class="btn-black"
            style="padding: 10px; max-width: 150px"
          >
            <feather-icon icon="CreditCardIcon" size="15" />
            <span> AMG Cards </span>
          </b-button>
          <b-button
            variant="success"
            @click="openNcrModal()"
            class="btn-black"
            style="padding: 10px; max-width: 200px"
          >
            <feather-icon icon="PlusIcon" size="15" />
            <span> Create Request </span>
          </b-button>
        </div>
      </template>
    </header-slot>
    <b-row v-if="![2, 26, 15].includes(moduleId)">
      <b-col>
        <b-nav card-header pills class="m-0">
          <b-nav-item
            :to="{
              name:
                moduleId == 5
                  ? $route.matched[2].meta.routeClient
                  : $route.matched[1].meta.routeClient,
            }"
            exact
            exact-active-class="active"
            :active="isClientTab"
            :link-classes="['px-3', bgTabsNavs]"
            >CLIENT
            <span
              v-if="cPendingNcrClient > 0 && isFinancialApproval"
              class="ml-1"
            >
              <feather-icon
                icon
                :badge="cPendingNcrClient > 99 ? '99+' : cPendingNcrClient"
                badge-classes="badge-danger"
              />
            </span>
          </b-nav-item>
          <b-nav-item
            :to="{
              name:
                moduleId == 5
                  ? $route.matched[2].meta.routeLead
                  : $route.matched[1].meta.routeLead,
            }"
            exact
            exact-active-class="active"
            :active="isLeadTab"
            :link-classes="['px-3', bgTabsNavs]"
            >LEAD
            <span
              v-if="cPendingNcrLead > 0 && isFinancialApproval"
              class="ml-1"
            >
              <feather-icon
                icon
                :badge="cPendingNcrLead > 99 ? '99+' : cPendingNcrLead"
                badge-classes="badge-danger"
              /> </span
          ></b-nav-item>
        </b-nav>
      </b-col>
      <b-col sm="2" v-if="moduleId == 5">
        <b-button
          variant="success"
          @click="openNcrModal()"
          class="btn-black ml-auto"
          style="padding: 10px; max-width: 200px"
        >
          <feather-icon icon="PlusIcon" size="15" />
          <span> Create Request </span>
        </b-button>
      </b-col>
    </b-row>
    <b-card
      v-if="![2, 26, 15].includes(moduleId)"
      no-body
      class="border-top-primary border-3 border-table-radius px-0"
    >
      <router-view :key="$route.name" />
    </b-card>

    <router-view v-else :key="$route.name" />

    <modal-search-lead-client v-if="showModal" @close="closeNcrModal" />
    <ModalCardComponent
      v-if="showCardsModal"
      :type="typeModal"
      @hideModal="hideModal"
    />

    <modal-global-search
      v-if="showModalGlobalSearch"
      :role-id="currentUser.role_id"
      :search-text="searchText"
      :program-id="programId"
      @closeModal="showModalGlobalSearch = false"
    />
  </div>
</template>

<script>
// MODALS
import ModalSearchLeadClient from "@/views/commons/components/request-ncr/views/modals/ModalSearchLeadClient.vue";
import ModalCardComponent from "@/views/commons/components/ncr-leads/components/ModalCardComponent.vue";
import ModalGlobalSearch from "@/views/commons/components/clients/modals/ModalGlobalSearch.vue";

// STORE
import { mapGetters, mapActions } from "vuex";
import { canNavigateDirect } from "@/libs/acl/routeProtectionDirect";
import { getUserData } from "@/auth/utils";
export default {
  beforeRouteEnter(to, from, next) {
    const userData = getUserData();
    if (!canNavigateDirect(to, userData.arrRoles, userData.eng_soft)) {
      return next({ name: "misc-not-authorized" });
    } else {
      return next();
    }
  },
  components: {
    ModalSearchLeadClient,
    ModalCardComponent,
    ModalGlobalSearch,
  },
  data() {
    return {
      showModal: false,
      showCardsModal: false,
      typeModal: "",
      searchText: "",
      programId: 7,
      showModalGlobalSearch: false,
    };
  },
  computed: {
    ...mapGetters({
      cPendingNcrClient: "RequestNcrStore/G_PENDING_NCR_CLIENT",
      cPendingNcrLead: "RequestNcrStore/G_PENDING_NCR_LEAD",
      currentUser: "auth/currentUser",
    }),
    moduleId() {
      return this.$route.matched[0].meta.module;
      // mncr
      // return this.$route.matched[0].meta.module == 28
      //   ? 11
      //   : this.$route.matched[0].meta.module
    },
    isFinancialApproval() {
      return [29, 25].includes(this.moduleId); // mncr return [11, 25].includes(this.moduleId)
    },
    isLeadTab() {
      const { routeLeadPending, routeLeadFinished, routeLeadAnnulled } =
        this.moduleId == 5
          ? this.$route.matched[3].meta
          : this.$route.matched[2].meta;
      return [routeLeadPending, routeLeadFinished, routeLeadAnnulled].includes(
        this.$route.name
      );
    },
    isClientTab() {
      const { routeClientPending, routeClientFinished, routeClientAnnulled } =
        this.moduleId == 5
          ? this.$route.matched[3].meta
          : this.$route.matched[2].meta;
      return [
        routeClientPending,
        routeClientFinished,
        routeClientAnnulled,
      ].includes(this.$route.name);
    },
  },
  async created() {
    await this.A_REQUEST_NCR_COUNTERS(this.moduleId);
  },
  methods: {
    ...mapActions({
      A_REQUEST_NCR_COUNTERS: "RequestNcrStore/A_REQUEST_NCR_COUNTERS",
      A_GLOBAL_SEARCH_CLIENTS: "clients-store/A_GLOBAL_SEARCH_CLIENTS",
    }),
    openNcrModal() {
      this.showModal = true;
    },
    closeNcrModal() {
      this.showModal = false;
    },
    hideModal() {
      this.showCardsModal = false;
    },
    showModalCard(type) {
      this.showCardsModal = true;
      this.typeModal = type;
    },
    search() {
      this.showModalGlobalSearch = true;
    },
  },
};
</script>
