<template>
  <div>
    <b-modal
      size="xmd"
      v-model="onControl"
      title="New NCR Request"
      @hidden="closeMe"
      centered
      hide-footer
      no-close-on-backdrop
      no-close-on-esc
    >
      <b-row>
        <b-col cols="12" md="6" xl="3" class="mt-1">
          <b-form-group label="Search by:">
            <b-form-radio-group
              v-model="selectedItem"
              :options="itemOptions"
              button-variant="outline-primary"
              buttons
              class="w-100"
              @input="manageInput"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6" xl="3" class="mt-1">
          <b-form-group label="Select NCR type:">
            <b-form-radio-group
              v-model="selectedNcr"
              :options="ncrOptions"
              button-variant="outline-primary"
              buttons
              class="w-100"
              @input="manageInput"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="12" xl="6" class="mt-1">
          <b-overlay :show="loadingSearch" rounded="lg" opacity="0.6">
            <template #overlay>
              <div class="d-flex align-items-center mt-2" style="gap: 4px">
                <b-spinner small type="grow" variant="primary" />
                <b-spinner type="grow" variant="primary" />
                <b-spinner small type="grow" variant="primary" />
                <!-- We add an SR only text for screen readers -->
                <span class="sr-only">Please wait...</span>
              </div>
            </template>
            <ValidationObserver ref="form">
              <b-form-group
                :label="
                  isSearchingLead ? 'Lead name:' : 'Client or Account Number:'
                "
              >
                <ValidationProvider
                  v-slot="{ errors }"
                  name="searchText"
                  rules="required"
                >
                  <b-input-group :aria-hidden="loadingSearch ? 'true' : null">
                    <b-form-input
                      type="text"
                      :placeholder="
                        isSearchingLead
                          ? 'Search by name'
                          : 'Search by name or account number'
                      "
                      v-model="searchText"
                      @keyup.enter="reloadTable()"
                      id="search-lead-or-client"
                      :state="errors[0] ? false : null"
                      :disabled="!formRadioValidator"
                      :autofocus="formRadioValidator"
                      ref="inputSearch"
                    />
                    <b-input-group-append>
                      <b-button
                        variant="outline-primary"
                        class="py-0"
                        @click="reloadTable()"
                        :disabled="!formRadioValidator"
                      >
                        SEARCH
                        <feather-icon icon="SearchIcon" />
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </ValidationProvider>
              </b-form-group>
            </ValidationObserver>
          </b-overlay>
        </b-col>
        <b-col cols="12">
          <template>
            <div>
              <b-table
                ref="table-search-lead-or-client"
                select-mode="single"
                sticky-header="400px"
                :busy="loadingSearch"
                :items="myProvider"
                :fields="fields"
                responsive="sm"
                show-empty
              >
                <template #table-busy>
                  <div class="text-center text-primary my-2">
                    <b-spinner class="align-middle" />
                    <strong>Loading...</strong>
                  </div>
                </template>

                <template #cell(client)="data">
                  <span class="d-flex align-items-center" style="gap: 5px">
                    <status-account
                      :account="data.item"
                      :text="false"
                    ></status-account>
                    <router-link
                      target="_blank"
                      :to="{
                        name: clientDashboardRouteName,
                        params: {
                          idClient: `${data.item.client_account_id}`,
                        },
                      }"
                    >
                      {{ data.item.client }}
                    </router-link>
                  </span>
                  <span class="d-block" style="margin-left: 18px">
                    {{ data.item.account }}
                  </span>
                </template>

                <template #cell(lead)="data">
                  <span class="d-flex align-items-center" style="gap: 5px">
                    <router-link
                      class="text-warning"
                      style="font-weight: bolder"
                      target="_blank"
                      :to="{
                        name: leadDashboardRouteName,
                        params: {
                          id: `${data.item.id}`,
                        },
                      }"
                    >
                      {{ data.item.lead }}
                    </router-link>
                  </span>
                </template>

                <template #cell(action)="{ item }">
                  <feather-icon
                    icon="NavigationIcon"
                    class="clickable text-primary"
                    v-b-tooltip.hover.top="'Select client'"
                    @click="openModalCreateNcr(item)"
                  />
                </template>
              </b-table>

              <div class="d-flex justify-content-end mt-5">
                <b-pagination
                  v-model="dataPaginator.paginate.currentPage"
                  align="fill"
                  first-number
                  last-number
                  :total-rows="dataPaginator.totalRows"
                  :per-page="dataPaginator.paginate.perPage"
                  @change="reloadTable"
                />
              </div>
            </div>
          </template>
        </b-col>
      </b-row>
    </b-modal>

    <modal-create-ncr-request
      v-if="showModalCreateNcr"
      :item="item"
      :ncrType="selectedNcr"
      @close="closeModalCreateNcr"
      @closeSearch="onControl = false"
      @reload="reloadTable"
    />
  </div>
</template>

<script>
// SERVICES
import NcrRequestService from "@/views/commons/components/request-ncr/services/ncr-request.service.js";

// COMPONENTS
import ModalCreateNcrRequest from "@/views/commons/components/request-ncr/views/modals/ModalCreateNcrRequest.vue";
import StatusAccount from "@/views/commons/components/clients/stylescomponents/StatusAccount.vue";

// DATA
import leadFields from "@/views/commons/components/request-ncr/data/leads-ncr.fields.js";
import clientFields from "@/views/commons/components/request-ncr/data/clients-ncr.fields.js";

// STORE
import { mapGetters } from "vuex";

export default {
  components: {
    ModalCreateNcrRequest,
    StatusAccount,
  },
  data() {
    return {
      onControl: false,
      itemOptions: [
        { text: "Lead", value: "LEAD", disabled: false },
        { text: "Client", value: "CLIENT", disabled: false },
      ],
      ncrOptions: [
        { text: "Automatic", value: "AUTOMATIC", disabled: false },
        { text: "Manual", value: "MANUAL", disabled: false },
      ],
      selectedItem: null,
      selectedNcr: null,
      searchText: "",
      loadingSearch: false,
      items: [],
      fields: [],
      leadFields,
      clientFields,
      dataPaginator: {
        totalRows: 0,
        toPage: 0,
        fromPage: 0,
        paginate: {
          currentPage: 1,
          perPage: 10,
        },
      },
      showModalCreateNcr: false,
      item: {
        lead_id: null,
        fullname: null,
        client_account_id: null,
        account: null,
        program_id: null,
        module_id: null,
        type: null,
      },
      formRadioValidator: false,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      clientDashboardRoutes: "clients-store/clientDashboardRoutes",
      leadDashboardRoutes: "clients-store/leadDashboardRoutes",
    }),
    isSearchingLead() {
      return this.selectedItem == "LEAD";
    },
    moduleId() {
      return this.$route.matched[0].meta.module == 29
        ? 11
        : this.$route.matched[0].meta.module;
    },
    clientDashboardRouteName() {
      return this.clientDashboardRoutes[this.moduleId] || null;
    },
    leadDashboardRouteName() {
      return this.leadDashboardRoutes[this.moduleId] || null;
    },
    typeClient() {
      switch (this.moduleId) {
        case 6:
          return 1;
        case 22:
          return 2;
        default:
          return null;
      }
    },
    isCrm() {
      return this.moduleId == 2;
    },
  },
  created() {
    this.onControl = true;

    this.selectedItem = this.isCrm ? "LEAD" : null;
    this.itemOptions[1].disabled = this.isCrm;
  },
  watch: {
    selectedItem(value) {
      if (!this.isCrm) this.reloadTable();
    },
  },
  methods: {
    async myProvider() {
      if (this.formRadioValidator) {
        const validate = await this.$refs.form.validate();
        if (!validate) return;
        this.loadingSearch = true;
        try {
          this.fields = this.getFields();
          const { currentPage, perPage } = this.dataPaginator.paginate;
          const { data } = await NcrRequestService.search({
            type: this.selectedItem,
            search: this.searchText,
            page: currentPage,
            per_page: perPage,
            module_id: this.moduleId,
            type_client: this.typeClient,
            advisor:
              this.currentUser.role_id == 3 ? this.currentUser.user_id : null, // the advisor only gets its clients
            user_id: this.currentUser.user_id,
            role_id: this.currentUser.role_id,
          });
          this.items = data.data;
          this.dataPaginator.totalRows = data.total;
          this.dataPaginator.toPage = data.to;
          this.dataPaginator.fromPage = data.from;
          this.dataPaginator.paginate.currentPage = data.current_page;
          return this.items;
        } catch (error) {
          this.showToast(
            "danger",
            "top-right",
            "Error",
            "XIcon",
            "Something went wrong"
          );
        } finally {
          this.loadingSearch = false;
        }
      }
    },

    manageInput() {
      this.formRadioValidator =
        this.selectedItem !== null && this.selectedNcr !== null;

      if (this.formRadioValidator) {
        this.$nextTick(() => {
          this.$refs["inputSearch"].focus();
        });
      }
    },

    async reloadTable() {
      this.$refs["table-search-lead-or-client"].refresh();
    },

    getFields() {
      if (this.selectedItem == "LEAD") {
        return this.leadFields;
      } else {
        let fields = this.clientFields.map((item) => {
          if (this.moduleId == 22) {
            if (item.key === "advisor") item.visible = false;
          }
          return item;
        });

        return fields.filter((x) => x.visible);
      }
    },

    openModalCreateNcr(row) {
      this.item.type = this.selectedItem;
      if (this.selectedItem == "LEAD") {
        this.item.lead_id = row.id;
        this.item.fullname = row.lead;
      } else {
        this.item.lead_id = row.lead_id;
        this.item.fullname = row.client;
        this.item.client_account_id = row.client_account_id;
        this.item.account = row.account;
        this.item.program_id = row.program_id;
        this.item.module_id = row.module_id;
      }
      this.showModalCreateNcr = true;
    },

    closeModalCreateNcr() {
      this.showModalCreateNcr = false;
      this.item = {
        lead_id: null,
        fullname: null,
        client_account_id: null,
        account: null,
        program_id: null,
        module_id: null,
        type: null,
      };
    },

    closeMe() {
      this.$emit("close");
    },
  },
};
</script>

