var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.moduleId !== 5)?_c('header-slot',{attrs:{"no-border-bottom":"","clients-search":true,"global-search":true},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('div',{staticClass:"w-100 d-flex justify-content-end",staticStyle:{"gap":"15px"}},[(_vm.moduleId !== 2)?_c('b-input-group',[_c('b-input-group-prepend',[_c('b-button',{attrs:{"variant":"outline-info"}},[_vm._v("GLOBAL SEARCH")])],1),_c('b-form-input',{staticStyle:{"height":"auto"},attrs:{"placeholder":"Search by Name, Account or the last 4 digits of Phone number"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.search.apply(null, arguments)}},model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}}),_c('b-input-group-append',[_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.search}},[_c('feather-icon',{attrs:{"icon":"SearchIcon"}})],1)],1)],1):_vm._e(),(_vm.isFinancialApproval)?_c('b-button',{staticClass:"btn-black",staticStyle:{"padding":"10px","max-width":"150px"},attrs:{"variant":"success"},on:{"click":function($event){return _vm.showModalCard('amg-cards')}}},[_c('feather-icon',{attrs:{"icon":"CreditCardIcon","size":"15"}}),_c('span',[_vm._v(" AMG Cards ")])],1):_vm._e(),_c('b-button',{staticClass:"btn-black",staticStyle:{"padding":"10px","max-width":"200px"},attrs:{"variant":"success"},on:{"click":function($event){return _vm.openNcrModal()}}},[_c('feather-icon',{attrs:{"icon":"PlusIcon","size":"15"}}),_c('span',[_vm._v(" Create Request ")])],1)],1)]},proxy:true}],null,false,2397144592)}):_vm._e(),(![2, 26, 15].includes(_vm.moduleId))?_c('b-row',[_c('b-col',[_c('b-nav',{staticClass:"m-0",attrs:{"card-header":"","pills":""}},[_c('b-nav-item',{attrs:{"to":{
            name:
              _vm.moduleId == 5
                ? _vm.$route.matched[2].meta.routeClient
                : _vm.$route.matched[1].meta.routeClient,
          },"exact":"","exact-active-class":"active","active":_vm.isClientTab,"link-classes":['px-3', _vm.bgTabsNavs]}},[_vm._v("CLIENT "),(_vm.cPendingNcrClient > 0 && _vm.isFinancialApproval)?_c('span',{staticClass:"ml-1"},[_c('feather-icon',{attrs:{"icon":"","badge":_vm.cPendingNcrClient > 99 ? '99+' : _vm.cPendingNcrClient,"badge-classes":"badge-danger"}})],1):_vm._e()]),_c('b-nav-item',{attrs:{"to":{
            name:
              _vm.moduleId == 5
                ? _vm.$route.matched[2].meta.routeLead
                : _vm.$route.matched[1].meta.routeLead,
          },"exact":"","exact-active-class":"active","active":_vm.isLeadTab,"link-classes":['px-3', _vm.bgTabsNavs]}},[_vm._v("LEAD "),(_vm.cPendingNcrLead > 0 && _vm.isFinancialApproval)?_c('span',{staticClass:"ml-1"},[_c('feather-icon',{attrs:{"icon":"","badge":_vm.cPendingNcrLead > 99 ? '99+' : _vm.cPendingNcrLead,"badge-classes":"badge-danger"}})],1):_vm._e()])],1)],1),(_vm.moduleId == 5)?_c('b-col',{attrs:{"sm":"2"}},[_c('b-button',{staticClass:"btn-black ml-auto",staticStyle:{"padding":"10px","max-width":"200px"},attrs:{"variant":"success"},on:{"click":function($event){return _vm.openNcrModal()}}},[_c('feather-icon',{attrs:{"icon":"PlusIcon","size":"15"}}),_c('span',[_vm._v(" Create Request ")])],1)],1):_vm._e()],1):_vm._e(),(![2, 26, 15].includes(_vm.moduleId))?_c('b-card',{staticClass:"border-top-primary border-3 border-table-radius px-0",attrs:{"no-body":""}},[_c('router-view',{key:_vm.$route.name})],1):_c('router-view',{key:_vm.$route.name}),(_vm.showModal)?_c('modal-search-lead-client',{on:{"close":_vm.closeNcrModal}}):_vm._e(),(_vm.showCardsModal)?_c('ModalCardComponent',{attrs:{"type":_vm.typeModal},on:{"hideModal":_vm.hideModal}}):_vm._e(),(_vm.showModalGlobalSearch)?_c('modal-global-search',{attrs:{"role-id":_vm.currentUser.role_id,"search-text":_vm.searchText,"program-id":_vm.programId},on:{"closeModal":function($event){_vm.showModalGlobalSearch = false}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }