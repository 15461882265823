<template>
  <div>
    <b-modal
      v-model="modalCenter"
      size="xmd"
      :title="titleModal"
      title-tag="h3"
      @hidden="hideModal"
    >
      <b-container>
        <filter-slot
          :filter="filters"
          :filter-principal="filterPrincipal"
          :total-rows="totalRows"
          :paginate="paginate"
          :start-page="startPage"
          :to-page="toPage"
          @reload="$refs['cardsTable'].refresh()"
          @reset-all-filters="refreshTable"
          @show-create-card-modal="showCardModal"
          :type="type"
        >
          <template #table>
            <b-table
              ref="cardsTable"
              class="m-0 p-0"
              :items="myProvider"
              :fields="visibleFields"
              :current-page.sync="paginate.currentPage"
              :per-page.sync="paginate.perPage"
              :busy.sync="isBusy"
              sticky-header="500px"
              show-empty
              responsive
              small
            >
              <template #table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle mr-1" />
                  <strong>Loading ...</strong>
                </div>
              </template>
              <template #cell(created_to)="data">
                <div>{{ data.item.created_by }}</div>
                {{ data.item.created_to | myGlobalDay }}
              </template>
              <template #cell(count)="data">
                <b-badge variant="primary" v-if="data.item.count > 0">{{
                  data.item.count
                }}</b-badge>
                <span v-else> - </span>
              </template>
              <!-- verify redirect -->
              <template #cell(actions)="data">
                <feather-icon
                  icon="TrashIcon"
                  size="15"
                  class="text-danger cursor-pointer"
                  @click="deleteCreditCard(data.item.card_id)"
                />
              </template>
            </b-table>
          </template>
        </filter-slot>
      </b-container>
    </b-modal>

    <CreateCreditCardModal
      v-if="showCreateCardModal"
      :type="type"
      @hideModal="hideCreateCardModal"
      @close="hideCreateCardModal"
    />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import NcrLeadsService from "@/views/administration/views/ncr-leads/services/NcrLeads.service.js";
import CardsTable from "@/views/commons/components/ncr-leads/components/CardsTable.vue";
import fields from "@/views/commons/components/ncr-leads/data/fields.ncr-leads.data.js";
import filters from "@/views/commons/components/ncr-leads/data/filters.ncr.leads.data.js";
import FilterSlot from "@/views/commons/components/ncr-leads/components/slot/FilterSlot.vue";
import CreateCreditCardModal from "@/views/commons/components/clients/dashboard/options/pay-module/modals/CreateCreditCard.vue";
import ClientService from "@/views/commons/components/clients/dashboard/services/clients.options.services.js";

export default {
  name: "ModalCardComponent",
  components: {
    CardsTable,
    FilterSlot,
    CreateCreditCardModal,
  },
  props: {
    type: {
      type: String,
    },
  },
  data() {
    return {
      showCreateCardModal: false,
      items: [],
      filters,
      isBusy: false,
      sortBy: "",
      sortDesc: true,
      arrayColumns: fields,
      modalCenter: true,
      startPage: 1,
      paginate: {
        currentPage: 1,
        perPage: 10,
      },
      totalRows: 0,
      toPage: 0,
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Card...",
        model: "",
      },
    };
  },
  computed: {
    fields: function () {
      return this.arrayColumns.filter((column) => column.visible);
    },
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    titleModal() {
      if (this.type == "crm-cards") {
        return "CRM CARDS";
      } else if (this.type == "amg-cards") {
        return "AMG CARDS";
      }
    },
    visibleFields() {
      return this.fields.filter((field) => field.visible);
    },
  },
  methods: {
    deleteCreditCard: async function (cardId) {
      const result = await this.showConfirmSwal();
      if (result.isConfirmed) {
        let params = {
          cardid: cardId,
          leadid: null,
          user_id: this.currentUser.user_id,
        };
        const { status } = await ClientService.deleteCardsById(params);
        if (status === 200) this.showSuccessSwal("OPERATION SUCCESSFULLY");
        this.refreshTable();
      }
    },
    hideModal() {
      this.$emit("hideModal");
    },
    hideCreateCardModal() {
      this.showCreateCardModal = false;
      this.refreshTable();
    },
    refreshTable() {
      this.$refs.cardsTable.refresh();
    },
    async myProvider(context) {
      const params = {
        date_from: this.filters[0].model || "",
        date_to: this.filters[1].model || "",
        name_text: "",
        order: "desc",
        orderby: 1,
        per_page: context.perPage || 10,
        page: context.currentPage || 1,
      };

      let data = "";
      if (this.type === "crm-cards") {
        data = await NcrLeadsService.getTpCardsCrm(params);
      } else if (this.type === "amg-cards") {
        data = await NcrLeadsService.getTpCards(params);
      }
      this.items = data.data.data;
      this.paginate.currentPage = data.data.current_page;
      //   this.amountT = this.items.length != 0 ? data.data.data[0].total : 0;
      this.toPage = data.data.to;
      this.startPage = data.data.from;
      //   this.endPage = data.data.last_page;
      this.paginate.perPage = data.data.per_page;
      //   this.startPageUrl = data.data.first_page_url;
      //   this.nextPage = data.data.first_page_url + 1;
      //   this.endPageUrl = data.data.last_page_url;
      //   this.nextPageUrlpage = data.data.next_page_url;
      //   this.prevPageUrl = data.data.prev_page_url;
      //   this.path = data.data.path;
      this.totalRows = data.data.total;

      //   if (this.items.length == 0) {
      //     this.isBusy == false;
      //   } else {
      //     return this.items;
      //   }
      return this.items;
    },

    showCardModal() {
      this.showCreateCardModal = true;
    },
  },
};
</script>
<style></style>
