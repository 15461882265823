export default [
  {
    key: "name",
    label: "Cardholder Name ",
    visible: true,
  },
  {
    key: "card",
    label: "Card Number",
    visible: true,
    thClass: "text-center",
    tdClass: "text-center",
  },
  {
    key: "card_expi_month",
    label: "MM",
    visible: true,
    thClass: "text-center",
    tdClass: "text-center",
  },
  {
    key: "card_expi_year",
    label: "YY",
    visible: true,
    thClass: "text-center",
    tdClass: "text-center",
  },
  {
    key: "cardsecuritycode",
    label: "CVC",
    visible: true,
    thClass: "text-center",
    tdClass: "text-center",
  },

  {
    key: "count",
    label: "Count",
    visible: true,
    thClass: "text-center",
    tdClass: "text-center",
  },
  {
    key: "created_to",
    label: "Created By",
    visible: true,
    thClass: "text-center",
    tdClass: "text-center",
  },
  {
    key: "created_by",
    label: "Created By",
    visible: false,
  },
  {
    key: "actions",
    label: "Actions",
    visible: true,
    thClass: "text-center",
    tdClass: "text-center",
  },
];
